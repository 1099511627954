import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CTA from "./CTA";
import Link from 'gatsby-link';
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h2>
            <span className="block text-base text-center text-theme font-semibold tracking-wide uppercase">
              Certus
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              White Label Bookkeeping
            </span>
          </h2>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            Certus is proud to say we are one of Toronto's leading providers of
            whitelabel bookkeeping services. If you are looking to increase the
            scale of your business we are here to help. We can provide you with
            the highest quality white label accounting services on the market.
          </p>
          <p className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            A “white label” solution is defined as a product or service that is
            created by one company but is distributed to others without
            branding. This allows the buyer to re-brand it as their own and
            redistribute the product to their customers. Purchasing a white
            label system allows your company to save time and money while
            producing a highly functioning product.
          </p>
        </div>

        <CTA />
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <p>
            This is the most practical and economic solution as you do not need
            to invest in developing your own product that is capable of
            competing with those already on the market. All the reseller needs
            to do is purchase the product from its original supplier and add
            their own branding. This is what allows the purchaser to make the
            product their own.
          </p>
          <h2 style={{marginTop: "0px"}}>Whitelabel Bookkeeping For Businesses</h2>
          <p>
            Our goal is to help you grow your accounting business.
            Our team of financial experts have spent the necessary hours to
            guarantee that our white label product will live up to your
            professional standards.
          </p>
          <p>
            The feedback we get regularly is that our clients cannot find time
            because they are buried in daily bookkeeping. We can help relieve
            this stress by providing you with a system that will manage all of
            your bookkeeping tasks for you.
          </p>
          <p>
            If you would like to receive a quote on our white label accounting
            solution please visit the{" "}
            <Link
              to="/contact-us"
              style={{
                textDecoration: "none",
                color: "#6B7280",
                "&:hover": {
                  background: "#efefef",
                },
                ":visited": {
                  color: "purple",
                },
              }}
            >
              contact us {" "}
            </Link>
            page on our website to schedule a consultation with one of our
            financial experts. They will identify your needs and provide you
            with a white label solution that will save you time and money.
          </p>
        </div>
      </div>
    </div>
  );
}
