import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title>White Label Bookkeeping | Certus Bookkeeping</title>
      <meta name="description" content="Look no further for white label bookkeeping services. Our team of professional bookkeepers can help you provide for your customers through our white label bookkeeping workflows." />
      <link rel="canonical" href="https://certusbookkeeping.com/white-label-bookkeeping" />
    </Helmet>
  );
}
