/* This example requires Tailwind CSS v2.0+ */
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import React from "react";
import { Helmet } from "react-helmet";
const faqs = [
  {
    question: "How do small businesses keep their accounting books?",
    answer:
      "The best way for a small business to keep their books is to hire a high end bookkeeping provider. A provider such as Certus Bookkeeping will ensure that the bookkeeping is done correctly and in a timely manner, which will allow the owner of the business to focus entirely on what is most important, growing their business.",
  },
  {
    question: "Why don't small businesses take their bookkeeping seriously?",
    answer:
      "Many small businesses do not take bookkeeping seriously because they see it as a workflow burden. This is way of thinking is a mistake for any small business owner. Bookkeeping is an integral part of any small business and must be considered when developing all workflows. A high end bookkeeping provider will ensure that your company is tax compliant and workflow efficient. It will also allow the owner to have full transparency into the profitability of his/her business via reconciled reports.",
  },
  {
    question: "What happens if I run payroll late?",
    answer:
      "Running payroll late comes with a myriad of potential penalties. Since payroll tax remittance is due to the CRA every month, once submitted, penalties for late submission will be assessed in accordance with the amount owed. The larger the late remittance, the larger the penalties.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
               "@type": "FAQPage",
               "mainEntity": [
                {
                  "@type": "Question",
                  "name": "How do small businesses keep their accounting books?",
                  "acceptedAnswer": 
                  {
                    "@type": "Answer",
                    "text": "<p>The best way for a small business to keep their books is to hire a high end bookkeeping provider. A provider such as Certus Bookkeeping will ensure that the bookkeeping is done correctly and in a timely manner, which will allow the owner of the business to focus entirely on what is most important, growing their business.</p>"
                  }
                }, 
                {
                    "@type": "Question",
                    "name": "Why don't small businesses take their bookkeeping seriously?",
                    "acceptedAnswer":
                  {
                    "@type": "Answer",
                    "text": "<p>Many small businesses do not take bookkeeping seriously because they see it as a workflow burden. This is way of thinking is a mistake for any small business owner. Bookkeeping is an integral part of any small business and must be considered when developing all workflows. A high end bookkeeping provider will ensure that your company is tax compliant and workflow efficient. It will also allow the owner to have full transparency into the profitability of his/her business via reconciled reports.<p>"
                  }
                },
                {
                  "@type": "Question",
                  "name": "What happens if I run payroll late?",
                  "acceptedAnswer": 
                    {
                      "@type": "Answer",
                      "text": "<p>Running payroll late comes with a myriad of potential penalties. Since payroll tax remittance is due to the CRA every month, once submitted, penalties for late submission will be assessed in accordance with the amount owed. The larger the late remittance, the larger the penalties.<p>"
                    } 
                }
              ]
              }`}
        </script>
      </Helmet>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
