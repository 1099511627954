import React from "react";
import { StaticImage } from "gatsby-plugin-image";
export default function Example() {
  return (
    <div>
      <div className="max-w-6xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-secondary rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Ready to Dive In?</span>
                <span className="block">Contact our team today.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-100">
                We can simplify your bookkeeping and help you automate your
                payroll. Contact a team member to get started today.
              </p>
              <a
                href="/contact-us"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-black-600 hover:bg-gray-100"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div
                className="aspect-w-5 md:aspect-h-0 "
                style={{
                  borderRadius: "5px",
                  webkitBorderRadius: "8px",
                  mozBorderRadius: "5px",
                  khtmlBorderRadius: "5px",
                  overflow: "hidden",
                  border: "3px transparent",
                  webkitMaskImage: "webkit-radial-gradient(white, black)",
                }}
              >
                <StaticImage
                  src="../../images/test-24.jpg"
                  alt="white-label-bookkeeping"
                  placeholder="fixed"
                  layout="fullWidth"
                  imgStyle={{ overflow: "hidden" }}
                  imgClassName="rounded-lg"
                  style={{ overflow: "hidden" }}
                  border="5px"
                  // You can optionally force an aspect ratio for the generated image
                  aspectRatio={1 / 1}
                  // This is a presentational image, so the alt should be an empty string
                  // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
                  formats={["auto", "webp", "avif"]}
                />
            </div>
        </div>
      </div>
    </div>
  );
}
